<template>
  <div class="into1">
    <div class="result" style="text-align:center;  margin-top:7vh;">
      <img src="../../assets/images/logo2.png" width="200px">
      <p style="font-size:0.8rem; margin-top:5vh;">支付成功!</p>
      <!-- <p>支付失败</p> -->
      <!-- <p>￥<span style="font-weight:300px; color:#000;">150.00</span></p>
      <p>订单支付￥150.00，支付成功自动激活</p> -->
    </div>
    <!-- <div class="result_info">
      <div>

        <span style="line-height: 0.8rem; height: 0.5rem; display: block; float: left; text-align:left;"><img
            src="../../assets/images/wachat.png" width="28px"></span>

        <span
          style="text-align:left; margin-left:0px; line-height: 0.6rem; height: 0.5rem; margin-left: 0.2rem; display: block; width: 3rem; float: left;">支付信息</span>
      </div>
      <div style="clear:both;"></div>
      <hr style="margin-top:0.3rem;">
      <div>
        <span style="line-height: 0.7rem; height: 0.7rem; display: block; float: left; text-align:left;">支付金额：</span>
          
        <span style="text-align:right; margin-left:0px; line-height: 0.7rem; height: 0.7rem; margin-left: 0.2rem; display: block; width: 3rem; float: right; color:#777;">￥150.00</span>
      </div>
      <div style="clear:both;"></div>
      <div>
        <span style="line-height: 0.7rem; height: 0.7rem; display: block;  float: left; text-align:right;">订单编号：</span>
          
        <span style="text-align:left; margin-left:0px; line-height: 0.7rem;  height: 0.7rem; margin-left: 0.2rem; display: block; width: 3rem; float: right; text-align: right; color:#777;">00000000000000</span>
      </div>
      <div style="clear:both;"></div>
    </div> -->
    <div class="result_but" style="margin-top:0.5rem;">
      <van-button round type="primary" size="large" style="background-color:#5E99F8; border:1px solid #5E99F8;">确认</van-button>
    </div>

  </div>
</template>

<script>
import api from "../../api/ztb";
import { Dialog } from 'vant';
import { ref } from 'vue'
import { createApp } from 'vue';
// Dialog({ message: '提示' });
export default {
  // name: "帛钥智能 - 渠道支付",
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },

  data() {
    return {
      high: ['rrt', 'uui'], //高风险区数据
      mid: [], //低风险区域
      dialogVisible: false,
      isShow: false,
      userName: '',
      userPhone: '',
      visible: false,
      jgName: "",
      checkPlatForm: 2, //媒介检测，0微信，1，支付宝，2浏览器
      orderId: "1dsf2343234324324",
      checked: 1,
      price: 98,
    };
  },

  setup() {
    const checked = ref('1');
    return { checked };
  },

  // computed: {

  // },

  created() {
    this.jgNames()
    this.IsWeixinOrAlipay()
    this.clkWach()
    // this.submitFormUser()
    this.bbsi();
  },

  methods: {
    bbsi(){
      // console.log("7777777777777777777777777")
      // console.log(this.$route.params)
      // console.log(this.$route.query)
    },
    clkZfb() {
      // 普通浏览器环境下
      if (this.checkPlatForm == 2) {
        if (this.checked == 1) { //支付宝
          this.$router.push({ path: 'AliPay', query: { price: this.price } });
          // Dialog.alert({
          //   message: this.checked + "支付宝"
          //   }).then(() => {
          // // on close
          // })
        } else {
          Dialog.alert({ //微信
            message: this.checked + "微信"
          }).then(() => {
            // on close
          })
        }

      }
      // 支付宝环境下
      if (this.checkPlatForm == 1) {
        if (this.checked == 1) {
          this.$router.push({ path: 'AliPay', query: { price: this.price } });
          // Dialog.alert({
          //   message: this.checked + "支付宝"
          //   }).then(() => {
          // // on close
          // })
        }
      }
      // 微信环境下
      if (this.checkPlatForm == 0) {
        if (this.checked == 2) {
          Dialog.alert({
            message: this.checked + "微信"
          }).then(() => {
            // on close
          })
        }
      }
    },

    IsWeixinOrAlipay() {
      var ua = window.navigator.userAgent.toLowerCase();
      //判断是不是微信
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        console.log("********************** WeiXIN模式")
        this.checkPlatForm = 0;
        return "WeiXIN";
      }
      //判断是不是支付宝
      if (ua.match(/AlipayClient/i) == 'alipayclient') {
        console.log("********************** Alipay模式")
        this.checkPlatForm = 1;
        return "Alipay";
      }
      //哪个都不是
      console.log("********************** 普通浏览器打开模式")
      this.checkPlatForm = 2;
      return "false";
    },

    jgNames() {
      // http://localhost:8081/#/into1?jg=0002
      if (this.$route.query.jg == '0001') {
        return this.jgName = "职通宝机构"
      } else if (this.$route.query.jg == '0002') {
        return this.jgName = "恒山机构"
      } else if (this.$route.query.jg == '0003') {
        return this.jgName = "华山机构"
      } else if (this.$route.query.jg == '0004') {
        return this.jgName = "嵩山机构"
      } else {
        return this.jgName = "其他机构..."
      }
    },

    clkWach() {
      this.isShow = !this.isShow
    },

    beforeClose(action, done) {
      if (action === 'confirm') {
        console.log("action = ", action)
        if (!this.userName || !this.userPhone) {
          this.$toast("请输入姓名及电话号码")
          done(false) //不关闭弹框
        } else {
          var data = new URLSearchParams();
          data.append('username', this.userName);   // 添加数据
          data.append('userphone', this.userPhone);   // 添加数据this.$route.query.jg
          data.append('institution_code', this.$route.query.jg);
          this.submitFormUser(data)
          setTimeout(done, 1000)
        }

      } else if (action === 'cancel') {
        // console.log("action = ", action)
        // this.$toast("取消绑定！")
        done(true) //关闭
      }
    },
    //发送绑定
    submitFormUser(params) {
      api.postCovInfo(params).then((res) => {
        console.log("==========================================oooop");
        console.log(res);
        // if (res.status == 200) {
        //   this.$toast("绑定成功！")
        //   console.log("绑定成功！");
        //   console.log(res.data);
        //   this.sqfk()
        // } else {
        //   console.log("绑定失败！");
        // }
      });
    },

    sqfk() {
      console.log('请求微信授权！！！！')
      setTimeout(
        top.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8120e608eacdd78f&redirect_uri=http://235f6cfb.cpolar.cn&response_type=code&scope=snsapi_userinfo&state=type%3Dquan%2Curl%3Dhttp%3A%2F%2Fmm.dianping.com%2Fweixin%2Faccount%2Fhome',
        5000
      )
    },

    // 判断是否中文
    isChinese(temp) {
      var re = /[^\u4E00-\u9FA5]/;
      if (re.test(temp)) return false;
      return true;
    },

  }
};
</script>

<style lang='less' scoped>
.into1 {
  font-size: 0.3rem;
}



.into1 .result {
  margin: 0 auto;
}

.into1 .result p {
  line-height: 0.8rem;
  text-align: center;
  color: #777;
}

.into1 .result p span {
  font-size: 0.8rem;

}

.into1 .result_info {
  background: #fff;
  border-radius: 0.5em;
  margin-top: 1rem;
  padding: 0.5rem;
}

.into1 .result_info p {
  line-height: 0.6rem;
}

.into1 .result_info span {
  text-align: right;
}

.into1 ul li {
  padding: 0.25rem;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: auto;
  overflow: hidden;
  text-align: center;
  // background-color: #39a9ed;
}

.into1 {
  background: #F3F3F3;
  padding: 0.5rem;
  height: 100vh;
}

.bg {
  height: 3.6rem;
  background: url("../../assets/images/area-1.png") no-repeat;
  background-size: cover;
  padding-top: 5rem;
}

.bg001 {
  height: 5rem;
  background: url("../../assets/images/area-1.png") no-repeat;
  background-size: cover;
  padding: 0.25rem 0.25rem;
  color: #D43030;
}

.bg002 {
  background: #fff;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  // padding: 0.2rem;
  border-radius: 0.2rem;
  min-height: 8rem;
  margin-bottom: 0.2rem;

  p {
    margin-top: 1rem;
    text-align: center;
    color: #777;
  }
}

.bg002 ul li {
  margin: 0.8rem 0.15rem;
  text-align: center;
}

.bg002 .button {
  background-color: #4D88F2;
  /* Green */
  border-radius: 0.15rem;
  border: none;
  color: white;
  padding: 0.15rem 0.32rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.25rem;
  width: 45vw;
  height: 10vw;
  line-height: 10vw;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.bg002 .button:hover {
  background-color: #4e8ebf;
  /* Green */
  border-radius: 0.15rem;
  border: none;
  color: white;
  padding: 0.15rem 0.32rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 45vw;
  height: 10vw;
  line-height: 10vw;
}

.content {
  background: #ECD773;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  padding: 0.2rem;
  border-radius: 0.2rem;
  min-height: 10rem;
  margin-bottom: 0.2rem;

  .th {
    margin-bottom: 0.3rem;
    font-size: 0.34rem;
  }

  .th:before {
    display: inline-block;
    width: 0.1rem;
    height: 0.3rem;
    margin-right: 0.1rem;
    background-color: #4169e2;
    content: "";
    vertical-align: middle;
  }

  .title {
    font-size: 0.3rem;
    margin-bottom: 0.3rem;

    img {
      width: 0.4rem;
      vertical-align: top;
    }
  }

  .list {
    li {
      margin-bottom: 0.2rem;
      color: #333;
      background: #f7f7f7;
      padding: 0.1rem;
      border-radius: 0.1rem;
      letter-spacing: 0.01rem;
      line-height: 0.5rem;
    }
  }
}
</style>